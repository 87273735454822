import React from "react";
import logo from '../assets/images/securin_logo_purple.svg'

const Header = () => {
    return (
        <header className="fixed top-0 left-0 w-full bg-white shadow-lg px-4 py-4 font-sans z-10">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <img
                        src={logo} 
                        alt="Icon"
                        className="w-22 h-8" 
                    />
                    <div className="text-lg text-white font-semibold">
                        CISA KEV Vulnerability Enrichment Dashboard
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
