import React from 'react';

export const PatchUpgradesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Component_233_7"
    width="24"
    height="24"
    data-name="Component 233 – 7"
    viewBox="0 0 24 24"
  >
    <path
      id="Rectangle_19230"
      fill="none"
      d="M0 0H24V24H0z"
      data-name="Rectangle 19230"
      opacity="0.1"
    />
    <path
      id="Subtraction_36"
      fill="none"
      stroke="#3caf28"
      strokeWidth="1"
      d="M12.006 20.254a.282.282 0 01-.111-.02 12.607 12.607 0 01-5.17-4.268 9.767 9.767 0 01-1.756-5.246 105.09 105.09 0 00-.053-1.14c-.037-.879-.065-1.511-.1-2.669v-.066c0-.038.059-.083.129-.098a21.945 21.945 0 006.966-2.97.185.185 0 01.105-.03.188.188 0 01.1.03 21.262 21.262 0 006.932 2.964.182.182 0 01.127.164v.388c0 .459 0 .933-.006 1.4-.01.426-.027.592-.046.768s-.036.339-.047.755a10.384 10.384 0 01-2.571 6.755 13.127 13.127 0 01-4.344 3.251.4.4 0 01-.155.032z"
      data-name="Subtraction 36"
    />
    <path
      id="Icon_material-refresh"
      fill="#3caf28"
      d="M13.894 7.351a4.6 4.6 0 101.2 4.4h-1.2a3.461 3.461 0 11-3.264-4.6 3.425 3.425 0 012.438 1.023l-.995 1.015 3.178.836-.391-3.648z"
      data-name="Icon material-refresh"
      transform="translate(1.367 1.269)"
    />
    <path
      id="Icon_awesome-check"
      fill="#3caf28"
      d="M1.089 6.909L.047 5.873a.159.159 0 010-.226l.227-.226a.161.161 0 01.227 0l.7.7 1.5-1.5a.161.161 0 01.227 0l.227.226a.159.159 0 010 .226l-1.84 1.836a.161.161 0 01-.226 0z"
      data-name="Icon awesome-check"
      transform="translate(10.711 6.581)"
    />
  </svg>
);
