import React, { useState } from "react";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight , MdArrowDropDown, MdArrowDropUp} from 'react-icons/md';
import {WorkaroundsIcon} from '../assets/icons/WorkaroundsIcon'
import { PatchIcon } from "../assets/icons/PatchIcon";
import { PatchUpgradesIcon } from "../assets/icons/PatchUpgradesIcon";
import { ExploitIcon } from "../assets/icons/ExploitIcon";
import { MalwareIcon } from "../assets/icons/MalwareIcon";

const Table = (props) => {
  const jsonData = props.jsonData;
  const itemsPerPage = 25;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("CVE");
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedData = [...jsonData].sort((a, b) => {
    if (sortColumn === "CVE") {
      return sortOrder === "asc" ? a.cve.localeCompare(b.cve) : b.cve.localeCompare(a.cve);
    }else if (sortColumn === "CVSS3") {
        const cvss3A = parseFloat(a.cvss) || 0;
        const cvss3B = parseFloat(b.cvss) || 0;
        return sortOrder === "asc" ? cvss3A - cvss3B : cvss3B - cvss3A;
    } else if (sortColumn === "DVRS") {
        const dvrsA = parseFloat(a.vrs) || 0;
        const dvrsB = parseFloat(b.vrs) || 0;
        return sortOrder === "asc" ? dvrsA - dvrsB : dvrsB - dvrsA;
    } else if (sortColumn === "THREATS") {
        const epssA = parseFloat(a.malware_count + a.exploit_count) || 0;
        const epssB = parseFloat(b.malware_count + b.exploit_count) || 0;
        return sortOrder === "asc" ? epssA - epssB : epssB - epssA;
    }
    return 0;
  });

  const totalItems = sortedData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Calculate the start and end indexes for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  // Get the data for the current page
  const currentPageData = sortedData.slice(startIndex, endIndex);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const Navigation = () => {
    return(
        <div className="flex justify-between my-4">
        <button
          className={`text-[#AB604B] bg-white border border-[#AB604B] font-bold hover:bg-gray-100 px-4 py-2 rounded-lg flex items-center ${currentPage === 1 ? 'cursor-not-allowed' : ''}`}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          <MdKeyboardDoubleArrowLeft className="mr-2" />
          Previous Page
        </button>
        <p className="text-gray-700">
          Showing {startIndex} - {endIndex} of {totalItems} results
        </p>
        <button
          className={`text-[#AB604B] bg-white border border-[#AB604B] font-bold hover:bg-gray-100 px-4 py-2 rounded-lg flex items-center ${currentPage === totalPages ? 'cursor-not-allowed' : ''}`}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next Page
          <MdKeyboardDoubleArrowRight className="ml-2" />
        </button>
      </div>
    )
  }

  return (
    // add md:px-10 to scrollable view
    <div className="py-8 2xl:w-3/4 xl:w-full xl:px-10 lg:w-3/4 lg md:px-6 md:w-full sm:w-full mx-auto rounded-lg">
      <Navigation />
      <div className="shadow mx-auto overflow-x-auto  border-b border-gray-200">
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-[#AB604B] text-white">
            <tr>
              <th 
              className={`text-left py-3 px-2.5 font-semibold text-sm whitespace-nowrap cursor-pointer ${sortColumn === "CVE" ? 'flex items-center' : ''}`}
              onClick={() => handleSort("CVE")}
              >
                CVE {sortColumn === "CVE" && (sortOrder === "asc" ? <MdArrowDropDown size="1.5em" /> : <MdArrowDropUp size="1.5em" />)}
              </th> 
              <th 
              className={`text-left py-3 px-2.5  font-semibold text-sm whitespace-nowrap cursor-pointer ${sortColumn === "CVSS3" ? 'flex items-center' : ''}`}
              onClick={() => handleSort("CVSS3")}
              >
                CVSS {sortColumn === "CVSS3" && (sortOrder === "asc" ? <MdArrowDropDown size="1.5em" /> : <MdArrowDropUp size="1.5em" />)}
              </th>
              <th 
              className={`text-left py-3 px-2.5  font-semibold text-sm whitespace-nowrap cursor-pointer ${sortColumn === "DVRS" ? 'flex items-center' : ''}`}
              onClick={() => handleSort("DVRS")}
              >
                Risk Index {sortColumn === "DVRS" && (sortOrder === "asc" ? <MdArrowDropDown size="1.5em" /> : <MdArrowDropUp size="1.5em" />)}
              </th>
              <th
              className={`text-left py-3 px-2.5  font-semibold text-sm whitespace-nowrap cursor-pointer ${sortColumn === "THREATS" ? 'flex items-center' : ''}`}
              onClick={() => handleSort("THREATS")}
              >
                No. of Threats {sortColumn === "THREATS" && (sortOrder === "asc" ? <MdArrowDropDown size="1.5em" /> : <MdArrowDropUp size="1.5em" />)}
              </th>
              <th className="text-left py-3 px-2.5  font-semibold text-sm whitespace-nowrap">
                Patch Availability {sortColumn === "PATCH" && (sortOrder === "asc" ? <MdArrowDropDown size="1.5em" /> : <MdArrowDropUp size="1.5em" />)}
              </th>
              <th className="text-left py-3 px-2.5  font-semibold text-sm whitespace-nowrap">
              Predictive Indicators
              </th>
              <th className="text-left py-3 px-2.5  font-semibold text-sm whitespace-nowrap">
                Vendor
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {currentPageData.map((row, index) => (
              <React.Fragment key={index}>
                <tr className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                  <td className="text-left text-[14px] text-[#000000] font-bold py-3 px-2 whitespace-nowrap">
                    <a className="hover:text-black" href={"https://nvd.nist.gov/vuln/detail/" + row.cve} target="_blank" rel="noreferrer">
                    {row.cve}
                    </a>
                  </td>
                  <td className="text-left text-[14px] py-3 px-2 whitespace-nowrap">
                    {Number(row.cvss).toFixed(2)}       
                  </td>
                  <td className="text-left text-[14px] py-3 px-2 whitespace-nowrap">{Number(row.vrs).toFixed(2)}</td>
                  
                  <td className="text-left py-3 px-2 whitespace-nowrap">
                  <div className="flex items-center">
                      <button title="Exploits" className="text-center mr-2">
                        <div className="flex items-center cursor-default">
                          <ExploitIcon/>
                          <div className="text-[14px]">{row.exploit_count}</div>
                        </div>
                      </button>
                      <button title="Malwares">
                        <div className="flex items-center cursor-default">
                          <MalwareIcon/>
                          <div className="text-[14px]">{row.malware_count}</div>
                        </div>
                      </button>
                    </ div>
                  </td>
                  <td className="text-left py-2 px-2 whitespace-nowrap">
                    <div className="flex items-center">
                      {row.fixes.is_workaround &&
                        <button className="cursor-default" title="Workarounds">
                          <WorkaroundsIcon/>
                        </button>
                      }
                      {row.fixes.is_patch &&
                        <button className="cursor-default" title="Patch">
                          <PatchIcon/>
                        </button>
                      }
                      {row.fixes.is_upgrade &&
                          <button className="cursor-default" title="Patch Upgrades">
                            <PatchUpgradesIcon/>
                          </button>
                      }
                    </ div>
                  </td>
                  <td className="text-left  py-3 px-2 whitespace-nowrap">
                  <div className="flex items-center ">
                      <button className={`text-[11px] w-[25px] h-[25px]  border-[1px]  cursor-default ${row.is_discussed === true ? 'bg-[#ffebcf] border-[#FFA22933]': ' border-[#D8D8D8]'} `} title="Discussed">
                        D
                      </button>
                      <button className={`text-[11px] w-[25px] h-[25px]  border-[1px] cursor-default ${row.has_poc === true ? 'bg-[#ffebcf] border-[#FFA22933]': ' border-[#D8D8D8]'}`} title="Proof of Concept">
                        POC
                      </button>
                      <button className={`text-[11px] w-[25px] h-[25px]  border-[1px]  cursor-default ${row.has_code_snippet === true ? 'bg-[#ffebcf] border-[#FFA22933]': ' border-[#D8D8D8]'}`} title="Code Snippet">
                        C
                      </button>
                      <button className={`text-[11px] w-[25px] h-[25px]  border-[1px] cursor-default ${row.has_pentester_framework === true ? 'bg-[#ffebcf] border-[#FFA22933]': ' border-[#D8D8D8]'}`} title="Pentester Framework">
                        PF
                      </button>
                      <button className={`text-[11px] w-[25px] h-[25px] border-[1px]  cursor-default ${row.is_exploited_in_the_wild === true ? 'bg-[#ffebcf] border-[#FFA22933]': ' border-[#D8D8D8]'}`} title="In the Wild">
                        Ex
                      </button>
                  </div>
                  </td>
                  <td className="text-left text-[14px] py-3 px-2 whitespace-nowrap capitalize">{row.vendor}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <Navigation />
    </div>
  );
};

export default Table;
