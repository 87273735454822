import React from "react";

const Details = () => {
    return(
        <div className="bg-gradient-to-r from-gray-100 to-[#F9FCFF] py-10">
        <div className="mb-10 2xl:w-3/4 xl:w-full xl:px-10 lg:w-3/4 lg md:px-6 md:w-full sm:w-full mx-auto rounded-lg">
            <div className="bg-white rounded-lg shadow p-6 mt-6 hover:shadow-lg">
                <h2 className="text-xl font-semibold mb-4 text-[#483889]">This dashboard offers a comprehensive compilation of the CISA Known Exploitable Vulnerabilities Catalog, featuring crucial details about each vulnerability, which include:</h2>
                <ul className="list-disc ml-6 leading-loose">
                    <li className="hover:text-md"> 
                        <b className="mr-2">CVE Identifier:</b>
                        Refers to the unique Common Vulnerabilities and Exposures (CVE) identification number assigned to each vulnerability, enabling precise tracking and reference.
                    </li>
                    <li>
                        <b className="mr-2">CVSS Score:</b> 
                        Numeric score signifying the severity of a security vulnerability, rated on a scale from 0 to 10, aiding in understanding the potential impact.
                    </li>
                    <li>
                        <b className="mr-2">Risk Index:</b> 
                        Securin's proprietary Risk Index scoring system assesses the security vulnerability's risk level based on real-world threats, providing valuable insights into its potential impact.
                    </li>
                    <li>
                        <b className="mr-2">No. of Threats:</b> 
                        Metric that quantifies the number of threats, including exploits, advanced persistent threats (APTs), and ransomware, associated with specific CVEs, helping gauge their susceptibility.
                    </li>
                    <li>
                        <b className="mr-2">Patches:</b> 
                        We track the availability of patches for individual CVEs, ensuring that you have the most up-to-date information on potential remediation actions.
                    </li>
                    <li>
                        <b className="mr-2">Predictive Indicators:</b> 
                        Threat Indicators encompass data points gathered per vulnerability, offering accurate, timely, and actionable information that is aggregated to prioritize response efforts effectively.<br />
                        D - Discussions on Deep and Dark Web; POC - Proof of Concept; C - Code Snippets; PF - PenTest Framework; Ex - Exploits
                    </li>
                    <li>
                        <b className="mr-2">Vendor:</b> 
                        Refers to the organization responsible for developing the product associated with the vulnerability, providing essential context for understanding the affected systems and applications.
                    </li>
                </ul>
            </div>
        </div>
        </div>
    )
}

export default Details;