import React from 'react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import Intro from './components/Intro';
import Details from './components/Details';

function App() {
  return (
    <div>
      <Header />
      <Intro/>
      <SearchBar/>
      <Details />
    </div>
  );
}

export default App;
