import React, { useEffect, useState } from "react";
import Table from "./Table";

const SearchBar = () => {
    const [queryField, setQueryField] = useState("cve");
    const [queryValue, setQueryValue] = useState("");
    const [jsonData, setJsonData] = useState([]);
    const [result, setResult] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch("cisa_cves.json");
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            console.log("got response from the server ..")
            const data = await response.json();
            setJsonData(data);
          } catch (error) {
            console.error('Error fetching JSON data:', error);
          }
        };
    
        fetchData();
      }, []);

    useEffect(() => {
        filterData();
    }, [jsonData, queryField, queryValue]);
    
    const filterData = () => {
        console.log("querying data ..")

        if(queryField == "none"){
            setResult(jsonData)
        } else if(queryField == "ransom"){
            let boolValue = queryValue == "true" ? true : false
            setResult(jsonData.filter((item) => item.ransomwareCount == boolValue))
        } else if(queryField == "cve"){
            setResult(jsonData.filter((item) => item.cve.includes(queryValue)))
        } else if(queryField == "vendor"){
            setResult(jsonData.filter((item) => item.vendor.includes(queryValue)))
        }
    }

    const changeQueryField = (filterType) => {
        setQueryField(filterType)
        if(filterType == "none"){
            filterData()
            setQueryValue("")
        } else if(filterType == "ransom"){
            setQueryValue("true")
        } else if(filterType == "cve"){
            setQueryValue("CVE-2004-0210")
        } else if(filterType == "vendor"){
            setQueryValue("microsoft")
        }
    }

    return(
        <div>
            <div className="flex flex-col items-center">
            {
                    <form 
                    className="w-2/4 mx-auto z-1"
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            filterData();
                            e.preventDefault();
                        }
                    }}
                    >
                    <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                        </div>
                        <input
                        type="search"
                        id="search"
                        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:border-blue-400 focus:border-2"
                        placeholder="Search"
                        value={queryValue}
                        onChange={(e) => setQueryValue(e.target.value)}
                        required
                        />
                        <div className="absolute right-2.5 bottom-2.5">
                        <select
                            className="px-1 py-1.5 text-white font-bold bg-gradient-to-r from-[#AB604B] to-[#EE7B22] rounded-lg shadow-sm focus:outline-none"
                            value={queryField}
                            onChange={(e) => changeQueryField(e.target.value)}
                        >
                            <option value="cve" className="text-black">CVE</option>
                        </select>
                        </div>
                    </div>
                    </form>
                }
            </div>
            <div className="my-2">
                { result && <Table jsonData={result}/>}
            </div>
        </div>
    );
}

export default SearchBar;