import React from 'react';

export const ExploitIcon = () => (
  <svg
    data-name="Icon / Exploit"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
  >
    <path data-name="Rectangle 19230" fill="none" d="M0 0h24v24H0z" />
    <g data-name="Group 77">
      <path
        data-name="Path 124"
        d="M7.388 18.424a5.478 5.478 0 1 1 7.599-1.014 5.428 5.428 0 0 1-7.599 1.014Z"
        fill="none"
        stroke="#f87876"
        strokeWidth={1.0002}
      />
    </g>
    <path
      data-name="Path 125"
      d="m12.668 9.095 1.689-2.16 2.498 1.953-1.689 2.16"
      fill="none"
      stroke="#f87876"
      strokeWidth={1.0002}
    />
    <path
      data-name="Path 127"
      d="m15.606 7.912.268-.344a1.93 1.93 0 0 1 2.711-.332 1.932 1.932 0 0 1 .333 2.712l-2.335 2.987a4.234 4.234 0 0 1-3.318 1.628"
      fill="none"
      stroke="#f87876"
      strokeWidth={1.0002}
    />
    <g data-name="Group 78" fill="none" stroke="#f87876">
      <path
        data-name="Line 78"
        d="m12.657 13.373-4.379 1.008"
        strokeWidth={1.0002}
      />
      <path
        data-name="Line 79"
        d="m9.552 15.93 1.83-4.104"
        strokeWidth={1.0002}
      />
      <path
        data-name="Line 80"
        d="m9.06 12.126 2.813 3.503"
        strokeWidth={1.0002}
      />
    </g>
  </svg>
);
