import React from "react";

const Intro = () => {
  return (
    <div className="flex py-5 justify-center items-center mt-16 bg-gradient-to-r from-[#483889] via-[#5C407C] to-[#DE742C] mb-3">
      <div className="max-w-4xl p-4">
        <h1 className="text-4xl font-bold mb-5 text-white">Introducing the CISA Known Exploited Vulnerabilities Enrichment Dashboard</h1>
        <p className="text-white">
          <span>
          Welcome to CISA KEV Vulnerability Enrichment Dashboard, empowering security researchers with rapid analysis of known and exploitable vulnerabilities identified by the CISA. Discover a comprehensive catalog enriched with critical data, including CVSS, Risk Index and Threats, to fortify your digital landscape.
          </span>
        </p>
        {/* <p className="flex justify-center font-bold mt-2 text-center mx-auto text-white">
          Effortlessly sort, search, and export the table data. Try it now!
        </p> */}
      </div>
    </div>
  );
};

export default Intro;
