import React from 'react';

export const PatchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Component_236_2"
    width="24"
    height="24"
    data-name="Component 236 – 2"
    viewBox="0 0 24 24"
  >
    <path
      id="Rectangle_19230"
      fill="none"
      d="M0 0H24V24H0z"
      data-name="Rectangle 19230"
      opacity="0.1"
    />
    <g id="Group_10287" data-name="Group 10287">
      <path
        id="Subtraction_34"
        fill="none"
        stroke="#3caf28"
        strokeWidth="1"
        d="M18.427 6.757a19.3 19.3 0 01-6.317-2.698.18.18 0 00-.191 0 19.807 19.807 0 01-6.348 2.7c-.061.018-.116.058-.116.093v.06c.044 1.459.077 2.92.139 4.378a8.858 8.858 0 001.6 4.774 11.54 11.54 0 004.711 3.886.333.333 0 00.247-.011 11.908 11.908 0 003.959-2.96 9.4 9.4 0 002.344-6.147c.02-.765.066-1.53.084-2.3.013-.543.005-1.087 0-1.627a.165.165 0 00-.112-.148z"
        data-name="Subtraction 34"
      />
      <path
        id="Icon_awesome-check"
        fill="#3caf28"
        d="M2.436 9.791L.105 7.473a.355.355 0 010-.5l.507-.5a.36.36 0 01.507 0L2.69 8.026l3.364-3.344a.36.36 0 01.507 0l.507.5a.355.355 0 010 .5l-4.125 4.1a.36.36 0 01-.507.009z"
        data-name="Icon awesome-check"
        transform="translate(8.7 4.625)"
      />
    </g>
  </svg>
);
