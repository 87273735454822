import React from 'react';

export const WorkaroundsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Component_235_2"
    width="24"
    height="24"
    data-name="Component 235 – 2"
    viewBox="0 0 24 24"
  >
    <g id="Icon_Base" data-name="Icon Base">
      <path
        id="Rectangle_19230"
        fill="none"
        d="M0 0H24V24H0z"
        data-name="Rectangle 19230"
        opacity="0.1"
      />
    </g>
    <g
      id="Group_10292"
      data-name="Group 10292"
      transform="translate(-589 -1129)"
    >
      <path
        id="Path_3991"
        fill="#3caf28"
        d="M7.744 10.958l-3.29-3.312L6 6.1l1.95-1.94 3.29 3.312zm1.21-4.165l-.009-.009a4.505 4.505 0 01-.56-.18 2.566 2.566 0 01-.524-.282.03.03 0 00-.033 0A2.642 2.642 0 017.3 6.6a4.711 4.711 0 01-.56.183c-.01 0-.019.01-.018.018l.007.2c0 .18.01.365.018.548a1.577 1.577 0 00.08.434 1.53 1.53 0 00.193.386 2.022 2.022 0 00.22.263 1.99 1.99 0 00.278.229 2.371 2.371 0 00.309.185.055.055 0 00.042 0 2.108 2.108 0 00.361-.215 2.051 2.051 0 00.316-.293 1.58 1.58 0 00.169-.231 1.545 1.545 0 00.124-.253 1.672 1.672 0 00.077-.275 1.93 1.93 0 00.031-.3l.006-.179c0-.066.007-.136.008-.2V6.812a.027.027 0 00-.009-.02"
        data-name="Path 3991"
        transform="translate(593.186 1133.426)"
      />
      <g
        id="Group_10277"
        data-name="Group 10277"
        transform="translate(587.531 1125.232)"
      >
        <g
          id="Rectangle_22122"
          fill="none"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Rectangle 22122"
        >
          <path
            stroke="none"
            d="M0 0h2.605v3.5a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5V0z"
            transform="rotate(45 -16.167 20.658)"
          />
          <path
            d="M0-.5h2.605a.5.5 0 01.5.5v3.5a1 1 0 01-1 1H.5a1 1 0 01-1-1V0A.5.5 0 010-.5z"
            transform="rotate(45 -16.167 20.658)"
          />
        </g>
        <g id="Path_3994" fill="#3caf28" data-name="Path 3994">
          <path
            d="M.2-.065A.375.375 0 11-.175.31.375.375 0 01.2-.065z"
            transform="translate(8.629 20.068)"
          />
          <path
            d="M.2-.065a.375.375 0 110 .75.375.375 0 010-.75z"
            transform="translate(8.629 20.068)"
          />
        </g>
        <g id="Path_3993" fill="#3caf28" data-name="Path 3993">
          <path
            d="M.288-.241a.375.375 0 11-.375.375.375.375 0 01.375-.375z"
            transform="translate(8.877 19.061)"
          />
          <path
            d="M.288-.24a.375.375 0 110 .75.375.375 0 010-.75z"
            transform="translate(8.877 19.061)"
          />
        </g>
        <g
          id="Ellipse_3664"
          fill="#3caf28"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Ellipse 3664"
          transform="translate(9.627 19.693)"
        >
          <circle cx="0.375" cy="0.375" r="0.375" stroke="none" />
          <circle cx="0.375" cy="0.375" r="0.125" fill="none" />
        </g>
      </g>
      <g
        id="Group_10279"
        data-name="Group 10279"
        transform="translate(602.757 1142.715)"
      >
        <g
          id="Rectangle_22122-2"
          fill="none"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Rectangle 22122"
        >
          <path
            stroke="none"
            d="M.5 0h1.605a.5.5 0 01.5.5V4H0V.5A.5.5 0 01.5 0z"
            transform="rotate(135 1.75 2.382)"
          />
          <path
            d="M.5-.5h1.605a1 1 0 011 1V4a.5.5 0 01-.5.5H0A.5.5 0 01-.5 4V.5a1 1 0 011-1z"
            transform="rotate(135 1.75 2.382)"
          />
        </g>
        <g id="Path_3994-2" fill="#3caf28" data-name="Path 3994">
          <path
            d="M.2-.065A.375.375 0 10.575.31.375.375 0 00.2-.065z"
            transform="translate(2.685 2.586)"
          />
          <path
            d="M.2-.065a.375.375 0 110 .75.375.375 0 010-.75z"
            transform="translate(2.685 2.586)"
          />
        </g>
        <g id="Path_3993-2" fill="#3caf28" data-name="Path 3993">
          <path
            d="M.288-.241a.375.375 0 10.375.375.375.375 0 00-.375-.375z"
            transform="translate(2.261 1.578)"
          />
          <path
            d="M.288-.24a.375.375 0 110 .75.375.375 0 010-.75z"
            transform="translate(2.261 1.578)"
          />
        </g>
        <g
          id="Ellipse_3664-2"
          fill="#3caf28"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Ellipse 3664"
          transform="translate(1.337 2.211)"
        >
          <circle cx="0.375" cy="0.375" r="0.375" stroke="none" />
          <circle cx="0.375" cy="0.375" r="0.125" fill="none" />
        </g>
      </g>
      <g
        id="Group_10280"
        data-name="Group 10280"
        transform="translate(594.572 1134.613)"
      >
        <g
          id="Rectangle_22122-3"
          fill="none"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Rectangle 22122"
        >
          <path
            stroke="none"
            d="M.5 0h1.606a.5.5 0 01.5.5V4H0V.5A.5.5 0 01.5 0z"
            transform="rotate(-45 2.225 .922)"
          />
          <path
            d="M.5-.5h1.606a1 1 0 011 1V4a.5.5 0 01-.5.5H0A.5.5 0 01-.5 4V.5a1 1 0 011-1z"
            transform="rotate(-45 2.225 .922)"
          />
        </g>
        <g id="Path_3994-3" fill="#3caf28" data-name="Path 3994">
          <path
            d="M.2.685A.375.375 0 10-.175.31.375.375 0 00.2.685z"
            transform="translate(1.587 1.467)"
          />
          <path
            d="M.2.685a.375.375 0 110-.75.375.375 0 010 .75z"
            transform="translate(1.587 1.467)"
          />
        </g>
        <g id="Path_3993-3" fill="#3caf28" data-name="Path 3993">
          <path
            d="M.288.509A.375.375 0 10-.087.134a.375.375 0 00.375.375z"
            transform="translate(1.835 2.825)"
          />
          <path
            d="M.288.51a.375.375 0 110-.75.375.375 0 010 .75z"
            transform="translate(1.835 2.825)"
          />
        </g>
        <g
          id="Ellipse_3664-3"
          fill="#3caf28"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Ellipse 3664"
          transform="translate(2.585 1.711)"
        >
          <circle cx="0.375" cy="0.375" r="0.375" stroke="none" />
          <circle cx="0.375" cy="0.375" r="0.125" fill="none" />
        </g>
      </g>
      <g
        id="Group_10278"
        data-name="Group 10278"
        transform="translate(602.757 1134.614)"
      >
        <g
          id="Rectangle_22122-4"
          fill="none"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Rectangle 22122"
        >
          <path
            stroke="none"
            d="M0 0h2.605v3.5a.5.5 0 01-.5.5H.5a.5.5 0 01-.5-.5V0z"
            transform="rotate(-135 1.888 1.954)"
          />
          <path
            d="M0-.5h2.605a.5.5 0 01.5.5v3.5a1 1 0 01-1 1H.5a1 1 0 01-1-1V0A.5.5 0 010-.5z"
            transform="rotate(-135 1.888 1.954)"
          />
        </g>
        <g id="Path_3994-4" fill="#3caf28" data-name="Path 3994">
          <path
            d="M.2.685A.375.375 0 11.575.31.375.375 0 01.2.685z"
            transform="translate(2.684 1.466)"
          />
          <path
            d="M.2.685a.375.375 0 110-.75.375.375 0 010 .75z"
            transform="translate(2.684 1.466)"
          />
        </g>
        <g id="Path_3993-4" fill="#3caf28" data-name="Path 3993">
          <path
            d="M.288.509A.375.375 0 11.663.134a.375.375 0 01-.375.375z"
            transform="translate(2.261 2.824)"
          />
          <path
            d="M.288.51a.375.375 0 110-.75.375.375 0 010 .75z"
            transform="translate(2.261 2.824)"
          />
        </g>
        <g
          id="Ellipse_3664-4"
          fill="#3caf28"
          stroke="#3caf28"
          strokeWidth="1"
          data-name="Ellipse 3664"
          transform="translate(1.337 1.711)"
        >
          <circle cx="0.375" cy="0.375" r="0.375" stroke="none" />
          <circle cx="0.375" cy="0.375" r="0.125" fill="none" />
        </g>
      </g>
    </g>
  </svg>
);
