import React from 'react';

export const MalwareIcon = () => (
  <svg
    data-name="Component 5429 \u2013 8"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
  >
    <path data-name="Rectangle 19230" fill="none" d="M0 0h24v24H0z" />
    <g data-name="Group 17692">
      <g
        data-name="Ellipse 3864"
        transform="translate(7.066 7.337)"
        fill="#fff"
        stroke="#f87876"
      >
        <circle cx={4.798} cy={4.798} r={4.798} stroke="none" />
        <circle cx={4.798} cy={4.798} r={4.298} fill="none" />
      </g>
      <g data-name="Group 16341" fill="#fff" stroke="#f87876">
        <path
          data-name="Path 6533"
          d="M8.055 9.689c-5.079.554-4.573 5.935-4.573 5.935s1.229-6.634 6.236-2.992"
        />
        <path
          data-name="Path 6534"
          d="M7.894 9.749c-2.561-5.709 3.284-7.135 3.284-7.135s-5.8 4.2.357 7.135"
        />
      </g>
      <g data-name="Group 16342" fill="#fff" stroke="#f87876">
        <path
          data-name="Path 6533"
          d="M15.944 9.689c5.079.554 4.573 5.935 4.573 5.935s-1.229-6.634-6.236-2.992"
        />
        <path
          data-name="Path 6534"
          d="M16.105 9.749c2.561-5.709-3.284-7.135-3.284-7.135s5.8 4.2-.357 7.135"
        />
      </g>
      <g data-name="Group 16343" fill="#fff" stroke="#f87876">
        <path
          data-name="Path 6533"
          d="M12.056 16.633c-2.871 4.224-7.385 1.253-7.385 1.253s6.435 2.028 5.567-4.103"
          strokeWidth={0.99982}
        />
        <path
          data-name="Path 6534"
          d="M11.928 16.746c3.841 4.937 7.84.446 7.84.446s-6.43 3.155-6.132-3.661"
          strokeWidth={0.99982}
        />
      </g>
      <g
        data-name="Ellipse 3863"
        transform="translate(8.865 9.137)"
        fill="#fff"
        stroke="#f87876"
      >
        <circle cx={2.999} cy={2.999} r={2.999} stroke="none" />
        <circle cx={2.999} cy={2.999} r={2.499} fill="none" />
      </g>
    </g>
  </svg>
);
